<template>
  <div class="guest-summary">
    <div class="summary-header d-flex align-center mb-4 flex-wrap">
      <div class="main-title mr-6">{{ $t("occupancy") }}</div>
      <v-btn class="flat-btn mr-auto my-0" text @click="toggleSpacesPopup">{{
        $t("set_reserved_spaces")
      }}</v-btn>
      <v-skeleton-loader v-if="!occupancyData" type="chip" />
      <div v-else class="main-title mr-8">
        <span :style="`color: ${mainColor} !important`">{{ occupancyData.spaces_occupied }}</span>
        <span>/{{ occupancyData.spaces_total }}</span>
      </div>
    </div>
    <div class="content">
      <v-skeleton-loader v-if="!occupancyData" class="half-doughnut-wrapper" type="card" width="290px" height="290px" />
      <div v-else class="donuts">
        <div v-for="chart in occupancyData.chart_types" :key="chart.header" class="half-doughnut-wrapper">
          <Doughnut :data="adaptChartData(chart)" class="doughnut" :options="chartOption" :plugins="chartPlugins" />
        </div>
      </div>
      <SpacesPopup v-if="isSettingSpaces" @hide="toggleSpacesPopup" :place_id="place" />
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { Chart as ChartJS, ArcElement, Tooltip, Legend, Title } from 'chart.js'
import { Doughnut } from 'vue-chartjs'
import { base } from '@/axios-api';

ChartJS.register(ArcElement, Tooltip, Legend, Title)

const mainColor = require('~/whitelabel.config')[process.env.VUE_APP_BRAND].lightColor;

const chartOption = {
  responsive: true,
  circumference: 180,
  rotation: -90,
  cutout: '65%',
  radius: '80%',
  plugins: {
    legend: {
      display: true,
      position: 'bottom',
      labels: {
        usePointStyle: true,
      },
      onClick: () => {},
    },
    title: {
      display: true,
      position: "top",
      align: "center",
      font: {size: '30px', family: 'Quicksand'}
    },
  }
};

const doughnutText = {
  id: 'doughnutText',
  beforeInit(chart) {
    const {ctx, data, options} = chart;
    options.plugins.title.text = data.datasets[0].title;
    ctx.save();
  },
  beforeUpdate(chart) {
    const {ctx, data, options} = chart;
    options.plugins.title.text = data.datasets[0].title;
    ctx.save();
  },
  beforeDatasetsDraw(chart) {
    const {ctx, data} = chart;
    const xCoor = chart.getDatasetMeta(0).data[0].x;
    const yCoor = chart.getDatasetMeta(0).data[0].y;


    ctx.font = 'bold 30px Quicksand';

    ctx.fillStyle = mainColor;

    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.save();

    ctx.fillText(data.datasets[0].occupancy, xCoor, yCoor - 10);
  }
}

const chartPlugins = [doughnutText];
export default {
  name: "DashboardOccupancy",
  props: {
    place: {
      default: 0,
    },
  },
  components: {
    SpacesPopup: () => import("./SpacesPopup.vue"),
    Doughnut,
  },
  data() {
    return {
      isSettingSpaces: false,
      chartOption,
      chartPlugins,
      occupancyData: null,
    };
  },
  computed: {
    mainColor() {
      return require('~/whitelabel.config')[process.env.VUE_APP_BRAND].lightColor;
    },
  },
  created() {
    this.getOccupancyData();
  },
  methods: {
    toggleSpacesPopup() {
      this.isSettingSpaces = !this.isSettingSpaces;
    },
    getOccupancyData() {
      base({
        url: '/occupancy',
        method: 'GET',
        params: {
          place_id: this.place,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        },
      })
        .then((response) => {
          console.log(response)
          this.occupancyData = response.data.charts[String(this.place)];
        });
    },
    adaptChartData(chartData) {
      let colors = [];
      switch (Object.keys(chartData.parts).length) {
        case 2:
          colors = [this.mainColor, '#00000020'];
          break;
        case 3:
          colors = [this.mainColor, '#ed7756', '#00000020'];
          break;
        case 4:
          colors = [this.mainColor, '#ed7756', '#484d4d', '#00000020'];
          break;
        case 5:
          colors = [this.mainColor, '#ed7756', '#484d4d', '#E0B823', '#00000020'];
          break;
        case 6:
          colors = [this.mainColor, '#ed7756', '#484d4d', '#E0B823', '#75CB5D', '#00000020'];
          break;
        default:
          colors = [this.mainColor];
          break;
      }
      return {
        // labels: chartData.parts_labels.map((label, key) => `${this.$t(label)} - ${chartData.parts_values[key]}`),
        labels: Object.keys(chartData.parts).map((label) => `${this.$t(label)} - ${chartData.parts[label].join(' / ')}`),
        datasets: [
          {
            title: `${this.$t(chartData.header)}`,
            backgroundColor: colors,
            data: Object.values(chartData.parts).map((value) => value[0]),
            occupancy: `${chartData.spaces_occupied}/${chartData.spaces_total}`,
          }
        ]
      };
    },
    handlePublication(push) {
      if (push.channel === `occupancy_${this.place}`) {
        this.occupancyData = push.data[String(this.place)];
      }
    },
  },
};
</script>

<style scoped lang="scss">
.summary-header {
  width: 90%;
}

.donuts {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
}

.half-doughnut-wrapper {
  position: relative;
  width: 290px;
  height: 290px;
  margin: auto;
}

.doughnut {
  position: relative;
  z-index: 1;
}

.chart-value {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 0;

  font-size: 28px;
  font-weight: bold;
  color: #464d4d;
}

.chart-name {
  margin: 0;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);

  font-size: 24px;
  color: #464d4d;
}
</style>
