<template>
  <div class="guest-summary">
    <div class="summary-header d-flex align-center mb-2 flex-wrap">
      <div class="main-title mr-6">{{ $t("kiosk_printer_status") }}</div>
    </div>
    <div class="content">
      <template v-if="!printerData">
        <div class="printer">
          <v-skeleton-loader class="place" type="text" height="12px" width="40px" />
          <v-skeleton-loader class="status" type="heading" height="24px" width="120px" />
          <v-skeleton-loader class="note" type="text" height="12px" width="190px" />
        </div>
      </template>
      <template v-else>
        <div class="printer" v-for="printer in printerData" :key="printer.kiosk.id">
          <template v-if="printerData.length > 1">
            <h3 v-if="printer.kiosk.type === 'kiosk_customersvc'" class="place">({{ $t('customer_svc') }})</h3>
            <h3 v-else class="place">({{
                $t('kiosk_printer_name', {type: $t(`kiosk_type.${printer.kiosk.type}`), location: $t(`kiosk_location.${printer.kiosk.location}`), locationName: printer.kiosk.location_name})
              }})</h3>
          </template>
          <p v-if="printer.status === PRINTER_STATUS.PAPER_OK" class="status">{{ $t('printer_status.paper_ok') }}</p>
          <p v-else-if="printer.status === PRINTER_STATUS.PAPER_LOW" class="status warning">{{ $t('printer_status.paper_low') }}</p>
          <p v-else-if="printer.status === PRINTER_STATUS.OUT_OF_PAPER" class="status error">{{ $t('printer_status.out_of_paper') }}</p>
          <span class="note">{{ $t('last_out_of_paper', { date: $moment(printer.last_out_of_paper).format('YYYY-MM-DD') }) }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import qs from 'qs';
import { base } from '@/axios-api';


export default {
  name: "PrinterStatus",
  props: {
    place: {
      default: 0,
    },
  },
  data() {
    return {
      printerData: null,
      PRINTER_STATUS: {
        OUT_OF_PAPER: "out_of_paper",
        PAPER_LOW: "paper_low",
        PAPER_OK: "paper_ok",
      }
    };
  },
  created() {
    this.getOccupancyData();
  },
  methods: {
    getOccupancyData() {
      base({
        url: '/devices/printer/status',
        method: 'GET',
        params: {
          placeID: this.place,
        },
        paramsSerializer: params => {
          return qs.stringify(params, {
            arrayFormat: 'repeat'
          })
        },
      })
        .then((response) => {
          console.log(response)
          this.printerData = response.data.body.content;
        });
    },
    handlePublication(push) {
      if (push.channel === `kiosk_status_${this.place}`) {
        this.printerData = push.data.content;
      }
    },
  },
};
</script>

<style scoped lang="scss">
.summary-header {
  width: 90%;
}

.content {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  column-gap: 30px;
  row-gap: 40px;
}

.printer {
  display: flex;
  flex-direction: column;
}

.place {
  margin: 0;
  font-size: 12px;
  font-weight: 400;
}

.status {
  margin: 0;
  margin-top: 10px;
  color: $green-darkened;
  font-size: 24px;
  font-weight: 700;
  line-height: 18px;

  &.warning {
    color: $error-orange;
  }

  &.error {
    color: $error-darkened;
  }
}

.note {
  margin-top: 17px;
  font-size: 12px;
}
</style>
